import React from "react";
import "./FAquestions.css";
import { Title } from "../../helpers/Title";
import { Advantages } from "../../helpers/Advantages";
import { DoctorQuestion } from "./DoctorQuestion";

export const FAquestions = () => {
  return (
    <section className="section section-faquestion" id="FAquestions">
      <div className="container">
        <div className="FAquestions">
          <Title text="Часто задаваемые" subtext="вопросы" />
          <div className="FAquestion-content">
            <div className="FAquestions-Questions">
              <Advantages
                buttonText="Что такое фолиевая кислота?"
                droptext="Фолиевая кислота это витамин из группы «В», необходимый для роста и развития кровеносной и иммунной систем."
                subtext="Особенно важна фолиевая кислота в первом триместре беременности – этот витамин участвует в формировании нервной системы плода, необходим для деления клеток, роста и развития всех органов и тканей, нормального развития зародыша, процессов кроветворения. Принимая данный препарат вы заботитесь о себе и о здоровье вашего будущего ребенка."
              />
              <Advantages
                buttonText="Когда следует принимать фолиевую кислоту?"
                droptext="Очень важно принимать фолиевую кислоту ежедневно, до беременности и в первые три месяца беременности."
              />
              <Advantages
                buttonText="В чем важность фолиевой кислоты?"
                droptext="Фолиевая кислота необходима для создания новых клеток. На первых неделях беременности развиваются органы плода, в том числе и мозг. "
                subtext="Нарушения в развитии нескольких клеток на ранней стадии беременности – прежде, чем женщина узнает о том, что она беременна, может привести к дефекту целой системы. Нервная трубка закрывается на третьей или четвертой неделе после оплодотворения, а дефект в ней или в мозгу плода может привести к порокам развития плода. Фолиевая кислота существенно уменьшает риск дефектов нервной трубки."
              />
              <Advantages
                buttonText="Возможно ли передозировать витамин B9?"
                droptext="Для большинства людей безопасен прием фолиевой кислоты в дозировке 1 мг в день."
              />
              <Advantages
                buttonText="В каких дозах рекомендуется принимать фолиевую кислоту?"
                droptext="Рекомендуется одна таблетка 0,4 мг ежедневно, во время всего периода фертильности."
              />
              <Advantages
                buttonText="Можно ли получить витамин B9 из продуктов питания?"
                droptext="Фолиевая кислота находится в зеленых овощах с листьями – салат, капуста, шпинат, брокколи, в выпечке из муки грубого помола, в цитрусовых, и в обогащенных добавками продуктах."
              />
            </div>
            <div className="FAquestions-Doctor">
              <DoctorQuestion />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
